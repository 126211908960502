import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoginForm from "../Components/LoginForm";
import RegisterForm from "../Components/RegisterForm";
import ForgotPasswordForm from "../Components/ForgotPasswordForm";
import "../css/Login.css";

const AuthContainer = () => {
  const [formState, setFormState] = useState("login");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      navigate("/main");
    }
  }, [navigate]);

  const handleLogin = async (username, password) => {
    if (!username || !password) {
      alert("사용자 이름과 비밀번호를 모두 입력하세요.");
      return;
    }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await axios.post(`${baseURL}/api/gosky/login`, {
        user_id: username,
        user_pw: password,
      });

      if (response.data.valid) {
        setMessage("로그인 성공!");
        localStorage.setItem("user_id", username);
        navigate("/main");
      } else {
        alert("사용자 이름 또는 비밀번호가 잘못되었습니다.");
      }
    } catch (error) {
      console.error("로그인 중 오류 발생:", error);
      alert("로그인 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.");
    }
  };

  const handleRegister = async ({
    username,
    password,
    confirmPassword,
    name,
    phone,
    email,
  }) => {
    // 모든 필드가 입력되었는지 확인
    if (
      !username ||
      !password ||
      !confirmPassword ||
      !name ||
      !phone ||
      !email
    ) {
      alert("모든 필드를 작성해 주세요.");
      return;
    }

    // 아이디가 5~20자인지 확인
    if (username.length < 5 || username.length > 20) {
      alert("아이디는 5자에서 20자 사이여야 합니다.");
      return;
    }

    // 아이디가 영어와 숫자로만 구성되었는지 확인
    const usernameRegex = /^[a-zA-Z0-9]+$/;
    if (!usernameRegex.test(username)) {
      alert("아이디는 영어와 숫자만 사용할 수 있습니다.");
      return;
    }

    // 비밀번호 유효성 검사 (최소 8자, 영어와 숫자 포함, 한글 미포함)
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;?/><.,]{8,}$/;
    const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

    if (!passwordRegex.test(password)) {
      alert(
        "비밀번호는 최소 8자 이상이어야 하며, 영어와 숫자를 포함해야 합니다."
      );
      return;
    }

    if (koreanRegex.test(password)) {
      alert("비밀번호에 한글은 포함될 수 없습니다.");
      return;
    }

    // 비밀번호와 비밀번호 확인이 일치하는지 확인
    if (password !== confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    // 전화번호가 11자리이며, 숫자로만 이루어져 있는지 확인
    const phoneRegex = /^\d{11}$/;
    if (!phoneRegex.test(phone)) {
      alert("전화번호는 11자리 숫자로만 이루어져 있어야 합니다.");
      return;
    }

    const nameRegex = /^[가-힣]{2,5}$/;
    if (!nameRegex.test(name)) {
      alert("이름은 2자에서 5자 사이의 한글만 입력 가능합니다.");
      return;
    }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await axios.post(`${baseURL}/api/gosky/register`, {
        user_id: username,
        user_pw: password,
        user_pw_confirm: confirmPassword,
        user_name: name,
        user_num: phone,
        user_email: email,
      });

      if (response.data.success) {
        alert(response.data.message);
        setFormState("login");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(`오류: ${error.response.data.message}`);
      } else if (
        error.response &&
        error.response.status === 500 &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(`서버 오류: ${error.response.data.message}`);
      } else {
        console.error("회원가입 중 오류 발생:", error);
        alert("회원가입 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.");
      }
    }
  };

  const handleForgotPasswordSubmit = async (username, email) => {
    if (!username || !email) {
      alert("아이디와 이메일을 모두 입력하세요.");
      return;
    }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }

      const response = await axios.post(
        `${baseURL}/api/gosky/forgot-password`,
        {
          user_id: username,
          user_email: email,
        }
      );
      alert(response.data.message); // 서버에서 반환된 메시지 표시
      navigate("/"); // 로그인 성공 시 메인 페이지로 이동
    } catch (error) {
      console.error("비밀번호 찾기 중 오류 발생:", error);
      alert("일치하는 이메일이 없습니다.");
    }
  };

  return (
    <div className={`login-container ${formState}`}>
      <div className="login-box">
        {formState === "login" && (
          <>
            <h2>Login</h2>
            <LoginForm
              handleLogin={handleLogin}
              switchToRegister={() => setFormState("register")}
              switchToForgotPassword={() => setFormState("forgotPassword")}
              message={message}
            />
          </>
        )}

        {formState === "forgotPassword" && (
          <>
            <h2>FORGOT PASSWORD</h2>
            <ForgotPasswordForm
              handleForgotPasswordSubmit={handleForgotPasswordSubmit}
              switchToLogin={() => setFormState("login")}
            />
          </>
        )}

        {formState === "register" && (
          <>
            <h2>REGISTER</h2>
            <RegisterForm
              handleRegister={handleRegister}
              switchToLogin={() => setFormState("login")}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AuthContainer;
