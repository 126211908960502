import React from "react";
import "../../css/GoskyChat.css"; // Import the CSS file for styling

const GoskyChat = () => {
  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome to the Main Page</h1>
        <p>This is your main Gosky_Chat.</p>
      </header>
      <main className="dashboard-main">
        {/* Add content or components you want to display on the main dashboard */}
      </main>
    </div>
  );
};

export default GoskyChat;
