import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-modal';

const Cloud = () => {
    const [currentPath, setCurrentPath] = useState('/test/');  // Set default path to '/'
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isImageFile, setIsImageFile] = useState(false); // 이미지 파일 여부
    const [isVideoFile, setIsVideoFile] = useState(false); // 동영상 파일 여부
    const [isDownloadable, setIsDownloadable] = useState(false); // 다운로드 가능 여부
    let baseURL = "https://api.gosky.kr";
    if (process.env.NODE_ENV === "development") {
      baseURL = "http://api.gosky.kr";
    }

    // Fetch files from the current directory
    const getFilesInDirectory = async (dir) => {
        try {
            const response = await axios.get(`${baseURL}/api/cloud/check/${dir}`);
            setFiles(response.data);
            setMessage('');
        } catch (error) {
            console.error('경로 파일 조회 실패:', error);
            setFiles([]);
            setMessage(error.response?.data?.error || '오류 발생');
        }
    };

    // Handle clicking on a directory or file
    const handleFileClick = async (file) => {
        if (file.isDirectory) {
            // If it's a directory, navigate inside it
            const newPath = `${currentPath}${file.name}/`;
            setCurrentPath(newPath);
        } else {
            // 파일 확장자 확인
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // 이미지 확장자
            const videoExtensions = ['mp4', 'webm', 'ogg']; // 동영상 확장자
            setIsImageFile(imageExtensions.includes(fileExtension));
            setIsVideoFile(videoExtensions.includes(fileExtension));

            if (imageExtensions.includes(fileExtension)) {
                // 이미지 파일의 경우 이미지 경로를 모달로 표시
                const imageUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(imageUrl);
                setSelectedFile(file.name);
                setIsDownloadable(false); // 이미지 파일은 다운로드 제공하지 않음
                setIsModalOpen(true);
            } else if (videoExtensions.includes(fileExtension)) {
                // 동영상 파일의 경우 동영상 경로를 모달로 표시
                const videoUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(videoUrl);
                setSelectedFile(file.name);
                setIsDownloadable(false); // 동영상 파일은 다운로드 제공하지 않음
                setIsModalOpen(true);
            } else {
                // 일반 파일인 경우 다운로드 링크 제공
                const fileUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(fileUrl);
                setSelectedFile(file.name);
                setIsDownloadable(true); // 다운로드 가능 파일
                setIsModalOpen(true);
            }
        }
    };

    useEffect(() => {
        // Load the default or clicked directory's content
        getFilesInDirectory(currentPath);
    }, [currentPath]);

    // Go back to the parent directory
    const goBack = () => {
        if (currentPath === '/test/') {
            alert("최상위 디렉토리를 열람할 권한이 없습니다.");
            return;
        }
        const parentPath = currentPath.slice(0, currentPath.lastIndexOf('/', currentPath.length - 2) + 1);
        setCurrentPath(parentPath || '/');
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setSelectedFile(null);
        setIsImageFile(false);
        setIsVideoFile(false);
        setIsDownloadable(false);
    };

    return (
        <div>
            <h1>파일 탐색기</h1>
            <div>
                <p>현재 경로: {currentPath}</p>
                <button onClick={goBack} disabled={currentPath === '/'}>상위 디렉토리로 이동</button>
                {message && <p>{message}</p>}
                <ul style={{ height: '500px', overflowY: 'scroll', textAlign: 'left'}}>
    {files.map((file, index) => (
        <li key={index} onClick={() => handleFileClick(file)} style={{ cursor: 'pointer' }}>
            {file.isDirectory ? '📁' : '📄'} {file.name}
        </li>
    ))}
</ul>
            </div>

            {/* Modal for displaying file content */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="File Content"
                ariaHideApp={false}
                style={{
                    content: {
                    },
                }}
            >
                <h2>{selectedFile}</h2>
                <button onClick={closeModal}>닫기</button>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {isImageFile ? (
                        <img
                            src={modalContent}
                            alt={selectedFile}
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        />
                    ) : isVideoFile ? (
                        <video
                            controls
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        >
                            <source src={modalContent} type={`video/${selectedFile.split('.').pop()}`} />
                            Your browser does not support the video tag.
                        </video>
                    ) : isDownloadable ? (
                        <a href={modalContent} download={selectedFile}>
                            {selectedFile} 다운로드
                        </a>
                    ) : (
                        modalContent
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default Cloud;
