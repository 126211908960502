import React from "react";

const Dashboard = () => {
  return (
    <>
      <h1>Welcome to the Main Page</h1>
      <p>This is your main dashboard.</p>
    </>
  );
};

export default Dashboard;
