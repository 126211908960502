import React, { useState, useEffect } from "react";
import axios from "axios";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [showPassword, setShowPassword] = useState({});
  let baseURL = "https://api.gosky.kr";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://api.gosky.kr";
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/gosky/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const createUser = async (newUser) => {
    try {
      const response = await axios.post(`${baseURL}/api/gosky/users`, newUser);
      setUsers([...users, response.data]);
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const updateUser = async (id, updatedUser) => {
    try {
      await axios.put(`${baseURL}/api/gosky/users/${id}`, updatedUser);
      setUsers(
        users.map((user) => (user.user_id === id ? { ...user, ...updatedUser } : user))
      );
      alert("레벨 변경이 성공적으로 완료되었습니다."); // Success message
    } catch (error) {
      console.error("Error updating user:", error);
      alert("레벨 변경 중 오류가 발생했습니다. 나중에 다시 시도해 주세요."); // Error message
    }
  };

  const handleUpdateAuth = (user) => {
    // Prompt the user for the new authorization level
    const newAuthLevel = prompt("부여할 레벨을 입력해주세요.", user.user_auth);

    // Check if the input is valid
    const authLevel = parseInt(newAuthLevel, 10);
    if (isNaN(authLevel) || authLevel < 0 || authLevel > 5) {
      alert("레벨은 1에서 5 사이의 숫자로 입력해야 합니다.");
      return;
    }

    // Prepare the updated user object
    const updatedUser = { ...user, user_auth: authLevel.toString() };

    // Call the updateUser function
    updateUser(user.user_id, updatedUser);
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`${baseURL}/api/gosky/users/${id}`);
      fetchUsers();
      alert("삭제가 완료 되었습니다."); // Alert after successful deletion
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("삭제 중 오류가 발생했습니다. 나중에 다시 시도해 주세요."); // Alert after failed deletion
    }
  };

  const togglePasswordVisibility = (userId) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  return (
    <div className="user-management">
      <h1>User Management</h1>
      <table className="user-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Email</th>
            <th>PassWord</th>
            <th>Auth</th>
            <th>PhoneNumber</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.user_id}>
              <td>{user.user_name}</td>
              <td>{user.user_id}</td>
              <td>{user.user_email}</td>
              <td>
                <input
                  className="user-password-input"
                  type={showPassword[user.user_id] ? "text" : "password"}
                  value={user.user_pw}
                  readOnly
                />
                <button className="user-password-button" onClick={() => togglePasswordVisibility(user.user_id)}>
                  {showPassword[user.user_id] ? "Hide" : "Show"}
                </button>
              </td>
              <td>{user.user_auth}</td>
              <td>{user.user_num}</td>
              <td>
                <button className="button-management" onClick={() => handleUpdateAuth(user)}>
                  권한 수정
                </button>
                <button className="button-delete" onClick={() => deleteUser(user.user_id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
