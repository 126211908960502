import React, { useState, useEffect } from "react";
import DraggableModal from "../Modal/DraggableModal";
import "../css/SettingsModal.css";

const SettingsModal = ({ show, onClose, onLogout, onChangeContent }) => {
  const [userAuth, setUserAuth] = useState(null);

  useEffect(() => {
    // Retrieve the user_id from localStorage
    const storedUserId = localStorage.getItem("user_id");

    // Fetch the user_auth value from the API
    const fetchUserAuth = async () => {
      if (storedUserId) {
        try {
          let baseURL = "https://api.gosky.kr";
          if (process.env.NODE_ENV === "development") {
            baseURL = "http://api.gosky.kr";
          }
          const response = await fetch(
            `${baseURL}/api/gosky/user/${storedUserId}/auth`
          );
          const data = await response.json();
          setUserAuth(data.userAuth);
        } catch (error) {
          console.error("Error fetching userAuth:", error);
        }
      }
    };

    fetchUserAuth();
  }, []);

  return (
    <DraggableModal show={show} onClose={onClose}>
      <div className="settings-section">
        <h3>페이지 이동</h3>
        <ul>
          <li onClick={() => onChangeContent("dashboard")}>홈 으로 이동</li>
          <li onClick={() => onChangeContent("UserNotification")}>공지 사항</li>
          <li onClick={() => onChangeContent("Ws_page")}>페이지</li>
          <li>준 비 중</li>
        </ul>
      </div>

      <div className="settings-section">
        <h3>AI 모델</h3>
        <ul>
          <li onClick={() => onChangeContent("GoskyChat")}>Gosky Chat</li>
          <li>준 비 중</li>
          <li>준 비 중</li>
        </ul>
      </div>

      <div className="settings-section">
        <h3>개인 페이지 설정</h3>
        <ul>
          <li onClick={() => onChangeContent("Mypage")}>내 정보</li>
          <li onClick={() => onChangeContent("Cloud")}>클라우드</li>
        </ul>
      </div>

      {/* Render admin section only if userAuth is 5 */}
      {userAuth === 5 && (
        <div className="admin-settings-section">
          <h3>관리자 권한</h3>
          <ul>
            <li onClick={() => onChangeContent("AdminManagement")}>
              회원 관리
            </li>
            <li onClick={() => onChangeContent("AdminNotification")}>
              공지 및 게시글 관리
            </li>
            <li onClick={() => onChangeContent("AdminCloud")}>
              클라우드 관리
            </li>
            <li onClick={() => onChangeContent("MailSend")}>
              메일 전송
            </li>
          </ul>
        </div>
      )}

      <button className="logout-button" onClick={onLogout}>
        Logout
      </button>
    </DraggableModal>
  );
};

export default SettingsModal;
