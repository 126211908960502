import React, { useState } from 'react';
import axios from 'axios';

const RegisterForm = ({ handleRegister, switchToLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
  
    // Check if the verification code was sent
    if (!isCodeSent) {
      alert("인증 코드를 보내고 이메일 인증을 완료해 주세요.");
      return;
    }
  
    // Check if the verification code was verified
    if (!isCodeVerified) {
      alert("이메일 인증을 완료해 주세요.");
      return;
    }
  
    // Proceed with registration if email is verified
    handleRegister({ username, password, confirmPassword, name, phone, email });
  };

  const sendVerificationCode = async () => {
    if (!email) {
      alert("이메일을 입력해 주세요.");
      return;
    }

    try {
        let baseURL = "https://api.gosky.kr";
        if (process.env.NODE_ENV === "development") {
          baseURL = "http://api.gosky.kr";
        }
      const response = await axios.post(`${baseURL}/api/gosky/send-verification-code`, {
        user_email: email,
      });

      if (response.data.success) {
        if (response.status === 201) {
            alert(response.data.message);
        } else {
            setIsCodeSent(true);
            alert(response.data.message);
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("인증 코드 전송 중 오류 발생:", error);
      alert("인증 코드 전송 중 오류가 발생했습니다.");
    }
  };

  const verifyCode = async () => {
    if (!verificationCode) {
      alert("인증 코드를 입력해 주세요.");
      return;
    }

    try {
        let baseURL = "https://api.gosky.kr";
        if (process.env.NODE_ENV === "development") {
          baseURL = "http://api.gosky.kr";
        }
      const response = await axios.post(`${baseURL}/api/gosky/verify-code`, {
        user_email: email,
        verification_code: verificationCode,
      });

      if (response.data.success) {
        setIsCodeVerified(true);
        alert("이메일 인증이 완료되었습니다.");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("인증 코드 확인 중 오류 발생:", error);
      alert("인증 코드 확인 중 오류가 발생했습니다.");
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="input-container">
        <input
          type="text"
          placeholder="ID"
          className="input-field"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Password"
          className="input-field"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Confirm Password"
          className="input-field"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Name"
          className="input-field"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Phone Number"
          className="input-field"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="input-container">
        <input
          type="email"
          placeholder="Email"
          className="input-field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isCodeVerified} // Disable the field if code is verified
        />
        <button type="button" className="Verification-button" onClick={sendVerificationCode} disabled={isCodeVerified}>
          Send
        </button>
      </div>
      {isCodeSent && (
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Verification Code"
            className="input-field"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={isCodeVerified} // Disable the field if code is verified
          />
          <button type="button" className="Verification-button" onClick={verifyCode} disabled={isCodeVerified}>
            Verify
          </button>
        </div>
      )}
      <button type="submit" className="register-button">
        Register
      </button>
      <p>Already have an account? <button type="button" className="signup" onClick={switchToLogin}>Login here.</button></p>
    </form>
  );
};

export default RegisterForm;
