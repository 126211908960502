import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from 'react-modal';

const AdminCloud = () => {
    const [currentPath, setCurrentPath] = useState('/');  // Set default path to '/'
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isImageFile, setIsImageFile] = useState(false); // 이미지 파일 여부
    const [isVideoFile, setIsVideoFile] = useState(false); // 동영상 파일 여부
    const [isDownloadable, setIsDownloadable] = useState(false); // 다운로드 가능 여부
    let baseURL = "https://api.gosky.kr";
    if (process.env.NODE_ENV === "development") {
      baseURL = "http://api.gosky.kr";
    }

    // Fetch files from the current directory
    const getFilesInDirectory = async (dir) => {
        setIsLoading(true);  // 로딩 시작
        try {
            const response = await axios.get(`${baseURL}/api/cloud/check/${dir}`);
            setFiles(response.data);
            setMessage('');
        } catch (error) {
            console.error('경로 파일 조회 실패:', error);
            setFiles([]);
            setMessage(error.response?.data?.error || '오류 발생');
        } finally {
            setIsLoading(false);  // 로딩 종료
        }
    };

    // Handle clicking on a directory or file
    const handleFileClick = async (file) => {
        if (file.isDirectory) {
            // If it's a directory, navigate inside it
            const newPath = `${currentPath}${file.name}/`;
            setCurrentPath(newPath);
        } else {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; // 이미지 확장자
            const videoExtensions = ['mp4', 'webm', 'ogg']; // 동영상 확장자
            setIsImageFile(imageExtensions.includes(fileExtension));
            setIsVideoFile(videoExtensions.includes(fileExtension));

            if (imageExtensions.includes(fileExtension)) {
                const imageUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(imageUrl);
                setSelectedFile(file.name);
                setIsDownloadable(false); // 이미지 파일은 다운로드 제공하지 않음
                setIsModalOpen(true);
            } else if (videoExtensions.includes(fileExtension)) {
                const videoUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(videoUrl);
                setSelectedFile(file.name);
                setIsDownloadable(false); // 동영상 파일은 다운로드 제공하지 않음
                setIsModalOpen(true);
            } else {
                const fileUrl = `${baseURL}/api/cloud/check/${currentPath}${file.name}`;
                setModalContent(fileUrl);
                setSelectedFile(file.name);
                setIsDownloadable(true); // 다운로드 가능 파일
                setIsModalOpen(true);
            }
        }
    };

    useEffect(() => {
        // Load the default or clicked directory's content
        getFilesInDirectory(currentPath);
    }, [currentPath]);

    // Go back to the parent directory
    const goBack = () => {
        const parentPath = currentPath.slice(0, currentPath.lastIndexOf('/', currentPath.length - 2) + 1);
        setCurrentPath(parentPath || '/');
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setSelectedFile(null);
        setIsImageFile(false);
        setIsVideoFile(false);
        setIsDownloadable(false);
    };

    return (
        <div>
            <h1>파일 탐색기</h1>
            <div>
                <p>현재 경로: {currentPath}</p>
                <button onClick={goBack} disabled={currentPath === '/'}>상위 디렉토리로 이동</button>
                {message && <p>{message}</p>}
                {isLoading ? (  // 로딩 상태일 때 로딩 메시지 표시
                    <p>로딩 중...</p>
                ) : (
                    <ul style={{ height: '500px', overflowY: 'scroll', textAlign: 'left' }}>
                        {files.map((file, index) => (
                            <li key={index} onClick={() => handleFileClick(file)} style={{ cursor: 'pointer' }}>
                                {file.isDirectory ? '📁' : '📄'} {file.name}
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* Modal for displaying file content */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="File Content"
                ariaHideApp={false}
                style={{
                    content: {
                    },
                }}
            >
                <h2>{selectedFile}</h2>
                <button onClick={closeModal}>닫기</button>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                    {isImageFile ? (
                        <img
                            src={modalContent}
                            alt={selectedFile}
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        />
                    ) : isVideoFile ? (
                        <video
                            controls
                            style={{ maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                        >
                            <source src={modalContent} type={`video/${selectedFile.split('.').pop()}`} />
                            Your browser does not support the video tag.
                        </video>
                    ) : isDownloadable ? (
                        <a href={modalContent} download={selectedFile}>
                            {selectedFile} 다운로드
                        </a>
                    ) : (
                        modalContent
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default AdminCloud;

