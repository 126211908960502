import React from "react";
import "../css/NotificationModal.css"; // Import the CSS file for the modal

const NotificationModal = ({ show, onClose, title, content }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="Notification-modal-overlay">
      <button onClick={onClose}>Close</button>
      <div className="Notification-modal-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default NotificationModal;
