import React, { useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import "../css/DraggableModal.css"; // Import your CSS for styling

const DraggableModal = ({ show, onClose, children }) => {
  const modalRef = useRef(null);
  const offset = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (modalRef.current) {
      // Set initial position to center of the screen
      modalRef.current.style.top = `${
        window.innerHeight / 2 - modalRef.current.offsetHeight / 2
      }px`;
      modalRef.current.style.left = `${
        window.innerWidth / 2 - modalRef.current.offsetWidth / 2
      }px`;
    }
  }, []);

  const onMouseDown = (e) => {
    if (modalRef.current) {
      offset.current = {
        x: e.clientX - modalRef.current.getBoundingClientRect().left,
        y: e.clientY - modalRef.current.getBoundingClientRect().top,
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    }
  };

  const onMouseMove = (e) => {
    if (modalRef.current) {
      const modalWidth = modalRef.current.offsetWidth;
      const modalHeight = modalRef.current.offsetHeight;

      // Calculate new position
      let newLeft = e.clientX - offset.current.x;
      let newTop = e.clientY - offset.current.y;

      // Boundary constraints
      const minLeft = 0;
      const minTop = 0;
      const maxLeft = window.innerWidth - modalWidth;
      const maxTop = window.innerHeight - modalHeight;

      // Ensure the modal stays within the viewport boundaries
      if (newLeft < minLeft) newLeft = minLeft;
      if (newTop < minTop) newTop = minTop;
      if (newLeft > maxLeft) newLeft = maxLeft;
      if (newTop > maxTop) newTop = maxTop;

      // Apply the new constrained position
      modalRef.current.style.left = `${newLeft}px`;
      modalRef.current.style.top = `${newTop}px`;
    }
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="draggable-modal" ref={modalRef}>
      <div className="modal-header" onMouseDown={onMouseDown}>
        <h2 className="modal-title">Settings</h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
      <div className="modal-content">{children}</div>
    </div>
  );
};

export default DraggableModal;
