import React, { useState, useEffect } from "react";
import "../../css/Notification.css";
import Modal from "../NotificationModal"; // Import the Modal component

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newNotification, setNewNotification] = useState("");
  const [userName, setUserName] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [selectedNotification, setSelectedNotification] = useState(null); // State to store selected notification
  let baseURL = "https://api.gosky.kr";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://api.gosky.kr";
  }
  useEffect(() => {
    const storedUserName = localStorage.getItem("user_id");
    if (storedUserName) {
      setUserName(storedUserName);
    } else {
      alert("사용자 이름이 로컬 스토리지에 없습니다.");
    }

    fetch(`${baseURL}/api/gosky/notifications`)
      .then((response) => response.json())
      .then((data) => setNotifications(data))
      .catch((error) => console.error("Error fetching notifications:", error));
  }, []);

  const handleTitleClick = (notification) => {
    setSelectedNotification(notification); // Set the selected notification
    setShowModal(true); // Show the modal
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleInputChange = (e) => {
    setNewNotification(e.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedNotification(null); // Clear the selected notification
  };

  const handleAddNotification = () => {
    if (newTitle.trim() && newNotification.trim() && userName.trim()) {
      const notificationData = {
        title: newTitle,
        notification: newNotification,
        user_name: userName,
      };

      fetch(`${baseURL}/api/gosky/notifications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(notificationData),
      })
        .then((response) => response.json())
        .then((data) => {
          setNotifications([
            ...notifications,
            {
              id: data.id,
              title: newTitle,
              text: newNotification,
              user_name: userName,
            },
          ]);
          setNewTitle("");
          setNewNotification("");
          alert("공지가 추가되었습니다.");
        })
        .catch((error) => {
          console.error("Error adding notification:", error);
          alert("공지를 추가하는 데 실패했습니다.");
        });
    } else {
      alert("제목과 공지 내용을 모두 입력해주세요.");
    }
  };

  const handleDeleteNotification = (id) => {
    fetch(`${baseURL}/api/gosky/notifications/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          setNotifications(
            notifications.filter((notification) => notification.id !== id)
          );
          alert("공지가 성공적으로 삭제되었습니다.");
        } else {
          console.error("Error deleting notification:", response.statusText);
          alert("공지 삭제하는 데 실패했습니다.");
        }
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
        alert("공지를 삭제하는 데 실패했습니다.");
      });
  };

  const formatContentWithLineBreaks = (text) => {
    return text.split("\n").map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="notification-container">
      <h1>Welcome to the Notification Page</h1>
      <h2>Create a New Notification</h2>
      <div>
        <input
          type="text"
          value={newTitle}
          onChange={handleTitleChange}
          placeholder="Enter the title here"
        />
        <textarea
          value={newNotification}
          onChange={handleInputChange}
          placeholder="Enter your notification here"
          rows="4"
          cols="50"
        />
        <button onClick={handleAddNotification}>Add Notification</button>
      </div>
      <h2>Existing Notifications</h2>
      <table>
        <thead>
          <tr>
            <th>공지 제목</th>
            <th>작성자</th>
            <th className="tr_delete">삭제</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => (
            <tr key={notification.id}>
              <td
                className="notification-title"
                onClick={() => handleTitleClick(notification)}
              >
                {notification.title}
              </td>
              <td>{notification.user_name}</td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteNotification(notification.id)}
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title={selectedNotification?.title}
        content={
          selectedNotification
            ? formatContentWithLineBreaks(selectedNotification.text)
            : ""
        }
      />
    </div>
  );
};

export default Notification;
