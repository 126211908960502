import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../css/UserNotification.css";

const UserNotification = () => {
  const [notifications, setNotifications] = useState([]);
  const [openNotificationId, setOpenNotificationId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let baseURL = "https://api.gosky.kr";
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://api.gosky.kr";
  }
  // Fetch notifications
  useEffect(() => {
    axios
      .get(`${baseURL}/api/gosky/notifications`)
      .then((response) => {
        setNotifications(response.data); // Assuming the data structure is in response.data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
        setError("Failed to fetch notifications.");
        setLoading(false);
      });
  }, []);

  // Toggle notification detail visibility
  const toggleNotificationDetail = (id) => {
    if (openNotificationId === id) {
      setOpenNotificationId(null); // Close the currently open notification
    } else {
      setOpenNotificationId(id); // Open the clicked notification
    }
  };

  if (loading) {
    return <p>Loading notifications...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="user-notification-container">
      <h2>Notifications</h2>
      <ul className="user-notification-list">
        {notifications.map((notification) => (
          <li key={notification.id} className="user-notification-item">
            <button
              onClick={() => toggleNotificationDetail(notification.id)}
              className="user-notification-title"
            >
              {notification.title}
            </button>

            {openNotificationId === notification.id && (
              <div className="user-notification-content">
                <p>
                  <strong>Posted by:</strong> {notification.user_name}
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(notification.created_at).toLocaleString()}
                </p>
                <p>
                  <strong>Content:</strong>
                </p>
                <pre>{notification.text}</pre>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserNotification;
