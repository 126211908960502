import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';

const LoginForm = ({ handleLogin, switchToRegister, switchToForgotPassword, message }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  return (
    <form onSubmit={onSubmit} className="login-form">
      <div className="input-container">
        <input
          type="text"
          placeholder="Username"
          className="login-input-field"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <FontAwesomeIcon icon={faUser} className="input-icon" />
      </div>
      <div className="input-container">
        <input
          type="password"
          placeholder="Password"
          className="login-input-field"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FontAwesomeIcon icon={faLock} className="input-icon" />
      </div>
      <button type="button" className="forgot-password" onClick={switchToForgotPassword}>Forgot Password?</button>
      <button type="submit" className="login-button">Log in</button>
      <p>Don't have an account? <button type="button" className="signup" onClick={switchToRegister}>Register</button></p>
      {message && <p className="login-message">{message}</p>}
    </form>
  );
};

export default LoginForm;
