import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/Main.css"; // Import the CSS file
import { FaCog } from "react-icons/fa"; // Import an icon from react-icons
import SettingsModal from "../Components/SettingsModal"; // Import the new SettingsModal component
import Dashboard from "../Components/Main_Pages/Pages_Dashboard"; // Import the Dashboard component
import UserManagement from "../Components/Main_Pages/Pages_UserManagement"; // Import the UserManagement component
import UserNotification from "../Components/Main_Pages/Pages_UserNotification";
import Notification from "../Components/Main_Pages/Pages_Notification"; // Import the Dashboard component
import Mypage from "../Components/Main_Pages/Pages_Mypage"; // Import the Dashboard component
import GoskyChat from "../Components/Main_Pages/Pages_GoskyChat"; // Import the Dashboard component
import Cloud from "../Components/Main_Pages/Pages_UserCloud"; // Import the Dashboard component
import AdminCloud from "../Components/Main_Pages/Pages_AdminCloud"; // Import the Dashboard component
import Ws_page from "../Components/Main_Pages/Pages_Ws_page"; // Import the Dashboard component
import MailSend from "./Load";

const MainPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState("dashboard"); // State to track current content

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    navigate("/login");
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev); // Toggle the modal open/close state
  };

  const handleContentChange = (content) => {
    setCurrentContent(content);
  };

  return (
    <div className="main-container">
      <div className="background-grid">
        {currentContent === "dashboard" && <Dashboard />}
        {currentContent === "UserNotification" && <UserNotification />}
        {currentContent === "AdminManagement" && <UserManagement />}
        {currentContent === "AdminNotification" && <Notification />}
        {currentContent === "GoskyChat" && <GoskyChat />}
        {currentContent === "Mypage" && <Mypage />}
        {currentContent === "Cloud" && <Cloud />}
        {currentContent === "AdminCloud" && <AdminCloud />}
        {currentContent === "MailSend" && <MailSend />}
        {currentContent === "Ws_page" && <Ws_page />}
        <FaCog onClick={toggleModal} className="settings-icon" />
      </div>
      <SettingsModal
        show={isModalOpen}
        onClose={toggleModal}
        onLogout={handleLogout}
        onChangeContent={handleContentChange} // Pass the handler to change content
      />
    </div>
  );
};

export default MainPage;
