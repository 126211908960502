import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/ResetPassword.css";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  // Extract the token from the URL using URLSearchParams
  const token = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    const validateToken = async () => {
      try {
        let baseURL = "https://api.gosky.kr";
        if (process.env.NODE_ENV === "development") {
          baseURL = "http://api.gosky.kr";
        }
        const response = await axios.post(
          `${baseURL}/api/gosky/validate-reset-token`,
          { token }
        );

        if (!response.data.success) {
          setError("유효하지 않거나 만료된 토큰입니다.");
        }
      } catch (err) {
        setError("유효하지 않거나 만료된 토큰입니다.");
      }
    };

    if (token) {
      validateToken();
    } else {
      setError("유효하지 않거나 누락된 토큰입니다.");
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if (!newPassword || !confirmPassword) {
        alert("모든 필드를 입력해야 합니다.");
      return;
    }

    if (newPassword !== confirmPassword) {
        alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (!passwordRegex.test(newPassword)) {
        alert("비밀번호는 8자리 이상이어야 하며, 영어와 숫자를 포함해야 합니다.");
        return;
      }

    try {
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }
      const response = await axios.post(`${baseURL}/api/gosky/reset-password`, {
        token,
        new_password: newPassword,
      });

      if (response.data.success) {
        setSuccess("비밀번호가 성공적으로 재설정되었습니다. 3초후 로그인 페이지로 이동합니다.");
        setTimeout(() => navigate("/login"), 3000); // 3초 후 리다이렉트
      } else {
        setError(response.data.message || "비밀번호 재설정에 실패했습니다.");
      }
    } catch (err) {
      setError("비밀번호 재설정 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="background-grid-resetpassword">
      <h2>비밀번호 변경</h2>
      {error ? (
        <div>
          <p className="error-message">{error}</p>
          <button onClick={() => navigate("/login")} className="error-button">로그인 페이지로 이동</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="reset-password-form">
          {success && <p className="success-message">{success}</p>}
          <input
            type="password"
            placeholder="Password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit">Change</button>
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
