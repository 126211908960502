import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from rea
import axios from "axios";
import "../css/Load.css"; // Import the CSS file

const Load = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [formData, setFormData] = useState({ to: "", subject: "", text: "" });
  const [status, setStatus] = useState(null); // To store success or error messages

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (!userId) {
      navigate("/login"); // Redirect if user is not logged in
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Set the base URL based on the environment
      let baseURL = "https://api.gosky.kr";
      if (process.env.NODE_ENV === "development") {
        baseURL = "http://api.gosky.kr";
      }

      // Send the request using axios
      const response = await axios.post(`${baseURL}/api/gosky/normal-email`, {
        to: formData.to,
        subject: formData.subject,
        text: formData.text,
      });

      // Check the response data for success
      if (response.data.success) {
        alert("Email sent successfully!");
        setFormData({ to: "", subject: "", text: "" }); // Clear the form
      } else {
        setStatus(response.data.message || "Failed to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("An error occurred.");
    }
  };

  return (
    <div className="background-grid">
      <h1>Mail Page</h1>
      <form onSubmit={handleSubmit} className="email-form">
        <label>
          To:
          <input
            type="email"
            name="to"
            value={formData.to}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Subject:
          <input
            type="text"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Message:
          <textarea
            name="text"
            value={formData.text}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Send</button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default Load;
