import React, { useState } from 'react';

const ForgotPasswordForm = ({ handleForgotPasswordSubmit, switchToLogin }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    handleForgotPasswordSubmit(username, email);
  };

  return (
    <form onSubmit={onSubmit}>
        <div className="input-container">
      <input
        type="text"
        placeholder="Username"
        className="input-field"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      </div>
      <div className="input-container">
      <input
        type="email"
        placeholder="Enter your email"
        className="input-field"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      </div>
      <button type="submit" className="login-button">Reset Password</button>
      <button type="button" className="back-to-login" onClick={switchToLogin}>Back to Login</button>
    </form>
  );
};

export default ForgotPasswordForm;
